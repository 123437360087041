export const websocketMiddleware = (storeAPI: any) => {
    let socket: WebSocket | null = null;

    return (next: any) => (action: any) => {
        switch (action.type) {
            case "CONNECT_WEBSOCKET":
                if (!socket || socket.readyState === WebSocket.CLOSED) {
                    socket = new WebSocket(action.payload.url);

                    socket.onopen = () => {
                        storeAPI.dispatch({ type: "WEBSOCKET_CONNECTED" });
                    };

                    socket.onerror = (err) => {
                        storeAPI.dispatch({ type: "WEBSOCKET_ERROR" });
                    };

                    socket.onclose = () => {
                        storeAPI.dispatch({ type: "WEBSOCKET_DISCONNECTED" });
                        socket = null;
                    };
                }
                break;

            case "SEND_WEBSOCKET_MESSAGE":
                if (socket && socket.readyState === WebSocket.OPEN) {
                    socket.send(JSON.stringify(action.payload));
                } else {
                    console.warn("WebSocket is not open. Cannot send message.");
                }
                break;

            case "DISCONNECT_WEBSOCKET":
                if (socket) {
                    socket.close();
                    socket = null;
                }
                break;

            default:
                break;
        }

        return next(action);
    };
};
