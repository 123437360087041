import { SET_USER } from "../actionTypes/userActionTypes";

const initialState = {
    user: null,
};

export const userReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_USER:
            return {
                user: action.payload,
            };
        default:
            return state;
    }
};
