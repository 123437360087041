import {
    Category,
    LetterCategory,
    MainCategory,
} from "../types/categoriesTypes";

export const categoriesData: (MainCategory | Category | LetterCategory)[] = [
    {
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="none"
            >
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M10 3a7 7 0 100 14 7 7 0 000-14zm-9 7a9 9 0 1118 0 9 9 0 01-18 0zm8-4a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zm.01 8a1 1 0 102 0V9a1 1 0 10-2 0v5z"
                />
            </svg>
        ),
        label: "Реквизиты",
        link: "/",
        getUrl: "/api/v1/user/requisite/list/",
        editUrl: "/api/v1/user/requisite/update/$id/",
        getItemUrl: "/api/v1/user/requisite/get/$id/",
        filters: [
            {
                name: "Активен/нет",
                field: "is_active",
                type: "radio",
                choices: [
                    { name: "Активен", value: true },
                    { name: "Неактивен", value: false },
                ],
            },
        ],
        fields: [
            { name: "#", field: "id" },
            {
                name: "Дата создания",
                field: "created_at",
            },
            {
                name: "Активен/нет",
                field: "status",
            },
            {
                name: "Банк",
                field: "bank",
            },
            {
                name: "Номер карты",
                field: "card_number",
            },
            {
                name: "Лимиты",
                field: "limits",
            },
            {
                name: "ФИО",
                field: "full_name",
            },
            {
                name: "Номер телефона",
                field: "phone_number",
            },
        ],
        addItemFields: [],
        inputFields: [
            { name: "Номер", field_name: "id", type: "text" },
            {
                name: "Активен/нет",
                field_name: "is_active",
                type: "status",
            },
            {
                name: "Банк",
                field_name: "bank",
                type: "text",
                locked: true,
            },
            {
                name: "Номер карты",
                field_name: "card_number",
                type: "text",
                locked: true,
            },
            {
                name: "Лимиты",
                field_name: "limits",
                type: "text",
                locked: true,
            },
            {
                name: "ФИО",
                field_name: "full_name",
                type: "text",
                locked: true,
            },
            {
                name: "Номер телефона",
                field_name: "phone_number",
                type: "text",
                locked: true,
            },
        ],
    },
];
