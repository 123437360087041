import { SET_WEBSOCKET_STATUS } from "../actionTypes/websocketActionTypes";

const initialState: any = {
    status: "disconnected",
};

export const websocketReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_WEBSOCKET_STATUS:
            return {
                ...state,
                status: action.payload,
            };
        default:
            return state;
    }
};
