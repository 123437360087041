import axios from "axios";
import { SetCurrentAction } from "../redux/actions/currentActionActions";
import {
    AddNotification,
    NotificationBody,
} from "../redux/actions/notificationsActions";
import store from "../redux/store";
import { generateUrl } from "./generateUrl";
import { websocketManager } from "./WebSocketManager";

export const editItem = async (
    url_part: string,
    newItem: any,
    data: any,
    onSuccess?: any
) => {
    let validUrl = generateUrl(url_part);

    if (data) {
        Object.keys(data).forEach((key) => {
            validUrl = validUrl.replace(`$${key}`, data[key]);
        });
    }

    await axios
        .put(validUrl, newItem)
        .then(async (res) => {
            const success = {
                message: `Обьект под номером ${data.id} был изменен.`,
                type: "success",
            };

            store.dispatch(AddNotification(success as NotificationBody));
            store.dispatch(SetCurrentAction(""));

            if (onSuccess) {
                await onSuccess(res.data);
            }

            const categoryLink = await store.getState().categoryReducer.category
                .link;

            if (categoryLink === "/") {
                if (
                    websocketManager &&
                    websocketManager?.getStatus() === "connected"
                ) {
                    try {
                        const message = JSON.stringify({
                            requisite_id: newItem.id || data.id,
                        });

                        websocketManager.sendMessage(message);
                    } catch (websocketError) {
                        console.error("WebSocket error:", websocketError);
                    }
                }
            }
        })
        .catch(() => {
            const error = {
                message: `Произошла ошибка во время изменения объекта под номером ${data.id}.`,
                type: "error",
            };

            store.dispatch(AddNotification(error as NotificationBody));
        });
};
