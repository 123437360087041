import { configureStore } from "@reduxjs/toolkit";
import { websocketMiddleware } from "./middlewares/websocketMiddleware";
import { rootReducer } from "./reducers/rootReducer";

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            websocketMiddleware
        ),
});
export default store;
