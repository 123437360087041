class WebSocketManager {
    private socket: WebSocket | null = null;
    private status: string = "disconnected"; // Status: connected, disconnected, error

    private onOpenCallbacks: Function[] = [];
    private onCloseCallbacks: Function[] = [];
    private onErrorCallbacks: Function[] = [];
    private onMessageCallbacks: Function[] = [];

    connect(url: string) {
        if (this.socket) {
            this.socket.close();
        }

        this.socket = new WebSocket(url);

        this.socket.onopen = () => this.handleOpen();
        this.socket.onclose = () => this.handleClose();
        this.socket.onerror = (err) => this.handleError(err);
        this.socket.onmessage = (event) => this.handleMessage(event);

        this.updateStatus("connecting");
    }

    private handleOpen() {
        this.updateStatus("connected");
        this.onOpenCallbacks.forEach((callback) => callback());
    }

    private handleClose() {
        this.updateStatus("disconnected");
        this.onCloseCallbacks.forEach((callback) => callback());
    }

    private handleError(error: any) {
        this.updateStatus("error");
        this.onErrorCallbacks.forEach((callback) => callback(error));
    }

    private handleMessage(event: MessageEvent) {
        this.onMessageCallbacks.forEach((callback) => callback(event));
    }

    private updateStatus(status: string) {
        this.status = status;
    }

    onOpen(callback: Function) {
        this.onOpenCallbacks.push(callback);
    }

    onClose(callback: Function) {
        this.onCloseCallbacks.push(callback);
    }

    onError(callback: Function) {
        this.onErrorCallbacks.push(callback);
    }

    onMessage(callback: Function) {
        this.onMessageCallbacks.push(callback);
    }

    sendMessage(message: string) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(message);
        } else {
            console.error("WebSocket is not open.");
        }
    }

    getStatus() {
        return this.status;
    }

    close() {
        if (this.socket) {
            this.socket.close();
        }
    }
}

export const websocketManager = new WebSocketManager();
