import { SetWebsocketStatus } from "../redux/actions/websocketActions";
import store from "../redux/store";
import { websocketManager } from "./WebSocketManager";

export const connectToWebSocket = async () => {
    websocketManager.onOpen(() => {
        store.dispatch(SetWebsocketStatus("connected"));
    });

    websocketManager.onClose(() => {
        store.dispatch(SetWebsocketStatus("disconnected"));
    });

    websocketManager.onError((err: any) => {
        store.dispatch(SetWebsocketStatus("error"));
    });

    return websocketManager;
};
