import axios from "axios";
import { SetAuth } from "../redux/actions/authActions";
import store from "../redux/store";
import { generateUrl } from "./generateUrl";

const accessTokenName = "ACCESS_GREXCHANGE_PARTNER_TOKEN";
const refreshTokenName = "REFRESH_GREXCHANGE_PARTNER_TOKEN";

export const getAccessToken = () => {
    return localStorage.getItem(accessTokenName);
};

export const getRefreshToken = () => {
    return localStorage.getItem(refreshTokenName);
};

export const setAccessToken = (token: string) => {
    return localStorage.setItem(accessTokenName, token);
};

export const setRefreshToken = (token: string) => {
    return localStorage.setItem(refreshTokenName, token);
};

export const validateToken = async () => {
    let isValid = false;

    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();

    if (accessToken) {
        const isTokenValid = await axios
            .post(generateUrl("api/v1/user/token/is_valid"), {
                token: `Bearer ${accessToken}`,
                user_role: "partner",
            })
            .then((res) => res.data || false)
            .catch(() => false);

        isValid = isTokenValid;
    }

    if (!isValid && refreshToken) {
        const isRefreshTokenValid = await axios
            .post(generateUrl("api/v1/user/token/access_from_refresh"), {
                token: `Bearer ${refreshToken}`,
                user_role: "partner",
            })
            .then((res) => {
                setAccessToken(res.data.access_token);
                setRefreshToken("");

                return true;
            })
            .catch(() => false);

        isValid = isRefreshTokenValid;
    }

    return isValid;
};

export const singInAccount = async (data: any) => {
    return await axios
        .post(generateUrl("api/v1/user/login"), {
            ...data,
            role: "partner",
        })
        .then((res) => {
            setAccessToken(res.data.access_token);
            setRefreshToken(res.data.refresh_token);

            return true;
        })
        .catch((err) => ({
            error:
                err.response.data.detail ||
                err.response.data.detail?.[0]?.msg ||
                "Что-то пошло не так...",
        }));
};

export const getProfile = async () => {
    await validateToken();

    const headers: any = {
        Authorization: `Bearer ${getAccessToken()}`,
    };

    return await axios
        .get(generateUrl("api/v1/user/partner/profile"), { headers })
        .then((res: any) => {
            return res.data;
        })
        .catch((err) => ({
            error:
                err.response.data.detail ||
                err.response.data.detail?.[0]?.msg ||
                "Что-то пошло не так...",
        }));
};

export const logOut = () => {
    setAccessToken("");
    setRefreshToken("");
    store.dispatch(SetAuth(false));
};
